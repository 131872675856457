<template>
  <div>
    <v-row class="inputArea">
      <v-col class="col-1 col-md-3"></v-col>
      <v-col class="text-center col-10 col-md-6">
        <div class="title mb-4">網頁連結:</div>
        <div class="d-flex align-center">
          <v-text-field :value="websiteLink" class="d-inline-block" outlined dense disabled
            hide-details></v-text-field>
          <v-btn color="primary" @click="copy(websiteLink)">
            <v-icon size="14">fa fa-copy</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>  
</template>
<script type="text/babel">
import formItemMixin from "@/components/form/mixins/formItemMixin";

export default {
  mixins: [formItemMixin],
  data() {
    return {
      
    };
  },
  computed: {
    providerId() {
      return this.$store.getters['member/providerId']
    },
    frontendDomain() {
      return this.$store.getters['base/frontendDomain']
    },
    websiteLink() {
      return `${this.frontendDomain.replace('liff', 'providerweb')}/${this.providerId}`
    },
  },
  watch: {

  },
  mounted() {
    
  },
  methods: {
    copy(link) {
      const copyText = link
      navigator.clipboard.writeText(copyText)
        .then(() => {
          this.$snotify.success(null, `已成功複製!`)
        })
    }
  }
};
</script>

<style lang="sass" scoped>
.inputArea
  input
    text-align: center !important
    color: rgba(0, 0, 0, 0.87) !important
  fieldset
    border-top-right-radius: initial !important
    border-bottom-right-radius: initial !important

  button
    border-top-left-radius: initial !important
    border-bottom-left-radius: initial !important
    height: 40px !important
</style>
